<template>
  <div class="column is-12">
    <base-table
      class="is-bordered is-narrow is-striped-2 is-fullwidth is-hoverable bankingTable"
      :columns="columnsData"
      :data="postList"
      :loading="loading"
    >
      <div slot="config" slot-scope="scope">
        <div class="field">
          <input :id="`active_${scope.row.id}`" type="checkbox" @change="changeStatus(scope.row.id)" :name="`active_${scope.row.id}`" class="switch is-rounded is-small" :checked="scope.row.active ? 'checked': ''">
          <label :for="`active_${scope.row.id}`"></label>
        </div>
      </div>
    </base-table>
    <base-pagination
      class="is-medium"
      :page="page"
      :pages="pages"
      :action="paginator"
      :loading="loading"
    />
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'BlogList',
  components: {
    BaseTable: () => import('@/components/Table'),
    BasePagination: () => import('@/components/BasePagination')
  },
  data () {
    return {
      columnsData: [
        {
          id: 'id',
          header: 'Id',
          class: 'column-mobile',
          accessor: (row) => `${row.id}`
        },
        {
          id: 'name',
          header: 'Nombre',
          class: 'column-mobile',
          accessor: (row) => `${row.name}`,
          link: true,
          type: 'link',
          route: (row) => ({ name: 'editar-blog', params: { postId: row.id } })
        },
        {
          id: 'author',
          header: 'Autor',
          class: 'column-mobile',
          accessor: (row) => `${row.author}`
        },
        {
          id: 'created_at',
          header: 'Fecha de creacion',
          class: 'column-mobile',
          accessor: (row) => moment(row.created_at).format('DD-MM-YYYY HH:mm:ss')
        },
        {
          id: 'active',
          header: 'Estatus',
          class: 'column-mobile',
          accessor: (row) => `${row.active ? 'Público' : 'Borrador'}`
        },
        { id: 'config', name: 'config', header: 'Acciones', class: 'column-mobile has-text-centered' }
      ]
    }
  },
  props: {
    postList: {
      type: Array
    },
    page: {
      type: Number,
      default: 1
    },
    pages: {
      type: Number,
      default: 1
    },
    paginator: {
      type: Function,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false
    },
    changeStatus: {
      type: Function,
      default: () => {}
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
